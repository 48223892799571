<template>
  <div class="fixed bg-[rgba(0,0,0,0.6)] w-full h-full z-[100] top-0 right-0" v-show="openEvent" @click="hiddenInterviewEvent">
  </div>
  <transition name="fade">
    <div class="sidebar absolute top-0 w-[480px] bg-white overflow-auto h-100"
      :class="[openEvent ? 'right-0' : '-right-[480px]']">
      <header class="w-full bg-white">
                    <div class="flex items-start justify-end w-full p-5">
                        <GenericButton
                            type="button"
                            @click="toggleModal"
                            class="inline-flex items-center px-5 py-2 leading-normal text-white rounded-lg bg-perperty">
                            <PlusIcon class="w-5 h-5 mr-2 text-white" /> {{$t('New Event')}}
                        </GenericButton>
                    </div>
                </header>
        <div class="w-full h-full p-5 is-light-mode calendar-container">
            <Qalendar  :events="convertedEvents" :config="config" @delete-event="confirmDelete"
                            @event-was-dragged="eventDragged" />
        </div>
    </div>
  </transition>

{{ console.log('job_id =',application) }}
  <AddEventModal
  :modalOpen="modalOpen"
  @update:toggleModal="toggleModal"
  category="interview"
  :routeName="route('interview.create.event',{'job': application.job_id, 'application_id': application.id})"
  />

</template>

<script setup>
import { PlusIcon } from "@heroicons/vue/24/solid";
import AddEventModal from "@/Pages/Agenda/Partials/AddEventModal.vue";
import { router, usePage } from '@inertiajs/vue3';
import {onMounted ,ref, watch} from "vue";
import { Qalendar } from 'qalendar';
import Swal from 'sweetalert2';
import GenericButton from "@/Components/GenericButton.vue";

const config = ref({
    defaultMode: 'month',

});
const convertedEvents =ref([]);
const modalOpen = ref(false)
const props=defineProps({
    application: {
        type: Object,
        default: null
    },
    openEvent: {
        type: Boolean,
        default: false
    },

});

const emit = defineEmits(['updateEventClose']);

const hiddenInterviewEvent = () => {
    emit('updateEventClose');
    console.log('sidebar close event click!!!!');
};

const deleteItem = (event) => {
    const id = { id: event };
    router.post(route('agendas.delete'), id, {
        onSuccess: () => {
            reloadData();
        }
    });
}
const confirmDelete = (event) => {
    Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this item!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success"
            });
            deleteItem(event);
        }
    });
}

const reloadData = () => {
    if(props.openEvent){
        axios.get(route('agendas.interviwe.event'))
        .then((response) => {
            convertedEvents.value = response?.data?.data.map(event => {
                return {
                    title: event.title,
                    time: {
                        start: event.start_time,
                        end: event.end_time
                    },
                    color: event.color || "blue",
                    isEditable: true,
                    id: event.id,
                    description: event.description || "No description available"
                };
            });
        }).catch((error) => {
            console.log(error);
        });
    }

}

const eventDragged = (event) => {
    const eventData = { eventData: event };
    router.post(route('agendas.dragged'), eventData, {
        onSuccess: () => {
            Swal.fire({
                title: "Saved!",
                text: "Event saved successfully.",
                icon: "success"
            });
            reloadData();
        }
    });
}

const toggleModal = () => {
    modalOpen.value = !modalOpen.value
}
onMounted( () => {
    console.log("Inter view event", props.application);
});

watch( () => {
    reloadData();
})
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999; /* Ensure it covers the sidebar */
  display: none; /* Hide by default */
}

.sidebar {
  position: fixed;
  top: 0;
  width: 480px;
  height: 100%;
  background-color: #fff;
  transition: right 2s; /* Sidebar transition */
  z-index: 1000;
}

.sidebar-open {
  right: 0; /* Display when 'open' is true */
}

/* Define sidebar transition */
.sidebar-enter-active, .sidebar-leave-active {
  transition: right 2s;
}
.sidebar-enter, .sidebar-leave-to {
  right: -480px; /* Initial and final positions */
}


.calendar-container {
    width: 100%;
    height: 800px;
}

.is-edit-icon {
    display: none !important;
}
</style>
